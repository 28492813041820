import './style/Common/global.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Experience from './pages/Experience';
import FooterComponent from './components/Common/FooterComponent';
import React, {useState, useEffect} from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import "./style/Common/vars.css"
import "./style/Common/animation.css"
import "./style/Common/common.css"
import "./style/Common/fonts.css"
import './style/Common/header.css'
import './style/Common/menu.css'

import { useCookies } from 'react-cookie'
import {get_project_data, get_tag_data, get_contact_data, get_about_data, getTokenWeb} from './common/js/webservice.js'
import { HttpStatusCode } from 'axios';
import {getCookie} from "./common/js/common.js"
import Journal from './pages/Journal.js';
import ReactGA from 'react-ga4';

export const TabContext = React.createContext(null);
function App({ nonce }) {
  useEffect(() => { 
    ReactGA.initialize("G-L02LR4QNFS",{ nonce : nonce});
  }, []);
  const [cookies, setCookie] = useCookies(['access_token', 'refresh_token'])
  const fetchAPI = async (pageName = "home", maxRetries = 3) => {
      if (maxRetries == 0) return;
      //new token will be set if previous api call was unauthorized, ignored otherwise
      await getTokenWeb(setCookie);
      if (pageName.toString().toLowerCase().trim() == "experience"){
          //get projects
          let dataProj = await get_project_data(getCookie("refresh_token"))       
          if (dataProj.statusCode == HttpStatusCode.Unauthorized){
            setCookie("refresh_token", "") 
            return fetchAPI(pageName,maxRetries -=1)
          }
          //get tags
          let dataTags = await get_tag_data(getCookie("refresh_token"))
          if (dataTags.statusCode == HttpStatusCode.Unauthorized){
            setCookie("refresh_token", "") 
            return fetchAPI(pageName,maxRetries -=1)
          }
          return [dataProj.data, dataTags.data]
      }
      else if (pageName.toString().toLowerCase().trim() == "about"){
        //get projects
        let dataAbout = await get_about_data(getCookie("refresh_token")) 
        return dataAbout;      
        // if (dataAbout.statusCode == HttpStatusCode.Unauthorized){
        //   setCookie("refresh_token", "") 
        //   return fetchAPI(pageName,maxRetries -=1)
        // }
        //return dataAbout.data;
      }
      else if (pageName.toString().toLowerCase().trim() == "contact"){
        //get projects
        let datContact = await get_contact_data(getCookie("refresh_token"))       
        if (datContact.statusCode == HttpStatusCode.Unauthorized){
          setCookie("refresh_token", "") 
          return fetchAPI(pageName,maxRetries -=1)
        }
        return datContact.data;
      }
  };
  return (
    <TabContext.Provider value={{GetDataForPage: fetchAPI}}>          
      <div className="app">
        <Router>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/home"element={<Home/>}/>
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/experience" element={<Experience/>} />
            <Route path="/journal" element={<Journal/>} />
          </Routes>
        </Router>
        <FooterComponent></FooterComponent>
      </div>
    </TabContext.Provider>
  );
}

export default App;
