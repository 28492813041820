export var headerClickedToHomePage = (navObj) => {
    setTimeout(function (){
        navObj("/home");
    }, 0); 
}
export var homePageToAboutPage = (navObj) => {
    setTimeout(function (){
        navObj("/about");
    }, 0); 
}
export var aboutPageInitAnimation = () => {return}
//hide contact form when done, restore if want to go back
export var hideShowContactForm = async (form, formHeightStr, messageContainer, hide = false) => {
    if (form){
      if (hide){
        //hide form
        form.style.overflowY = "hidden";
        let heightKeyFrames =[{ height: formHeightStr + 'px', padding: '1em'},{ height: '0px',  padding: '0' }];
        await form.animate(heightKeyFrames,
          {
            easing: "linear",
            duration: 500,
            fill: "forwards"
          });
        let hideKeyFrames =[{ opacity: 1},{ opacity: 0}];
        await form.animate(hideKeyFrames,
            {
              easing: "linear",
              duration: 100,
              fill: "forwards"
            });
          //show message container
          if (messageContainer){
            messageContainer.style.display = "flex";
          }
      }
      else{
        //hide message container
        if (messageContainer){
          messageContainer.style.display = "none";
        }
        //show/restore form
        form.style.overflowY = "unset";
        let heightKeyFrames =[{ height: '0px', padding: '0em'}, { height: formHeightStr + 'px', padding: '1em'}];
        await form.animate(heightKeyFrames,
          {
            easing: "ease-out",
            duration: 500,
            fill: "forwards"
          });
          let hideKeyFrames =[{ opacity: 0},{ opacity: 1}];
          await form.animate(hideKeyFrames,
              {
                easing: "linear",
                duration: 100,
                fill: "forwards"
          });
      }  
    }
}
//upon height restore - if validation errors appear we must re-adjust the height to accomodate extra space
export var adjustContactFormHeight = async(form, currHeightStr, adjustedHeightStr) =>{
  if (form && currHeightStr != adjustedHeightStr){
    let heightKeyFrames =[{ height: currHeightStr + 'px'}, { height: adjustedHeightStr + 'px'}];
    await form.animate(heightKeyFrames,
      {
        easing: "ease-out",
        duration: 100,
        fill: "forwards"
      });
  }
}