import React from "react";

const JournalComponent = () => {
  return (
    <>
      <div id="journal_container" className="flexCol">
        <div id="journal_box" className="flexCol">
            <div className="divTitle">
                <h2><strong>JOURNAL</strong></h2>
                <h3>My odd thoughts, here and there.</h3>
            </div> 
            <div id="journal_main">
                <h1>COMING SOON!</h1>
            </div>
        </div>
     </div>
    </>
  );
};

export default JournalComponent;
