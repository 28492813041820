import {getCookie} from "../js/common.js";
import {get_token} from "../js/token";

const BASE_URL = function() { return process.env.REACT_APP_API_URL };
var HEADERS = { }; 

export async function api_get(url, token) {
    try {
      const response = await fetch(BASE_URL() + url, {
        method: "GET",
        headers: HEADERS
      }); 
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null; 
    }
  }
export async function api_post(url, data_obj) {
    try {
      const response = await fetch(BASE_URL() + url, {
        method: "POST",
        headers: HEADERS,
        data: data_obj
      }); 
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null; 
    }
}
export async function get_about_data(jwt) {
  return  {
        "card_one": [
            {
                "key": "Location",
                "value": "Chicago, Illinois"
            },
            {
                "key": "Languages Spoken",
                "value": "English, Polish"
            },
            {
                "key": "Education",
                "value": "B.S. & M.S. in Computer Science @ Illinois Institute of Technology"
            },
            {
              "key": "Favorite Stacks",
              "value": "React.js, .NET, Xamarin"
            },
            {
                "key": "Light or Dark Mode",
                "value": "Dark Mode"
            },
            {
                "key": "Memorable Travels",
                "value": "South Korea, Italy, Poland"
            }
        ],
        "card_two": [
            {
                "key": "Python",
                "value": 4,
                "time": "1 Yr"
            },
            {
                "key": "C#",
                "value": 4.5,
                "time": "5 Yrs"
            },
            {
                "key": "React.js",
                "value": 3.5,
                "time": "6 mos"
            },
            {
                "key": "Xamarin Forms",
                "value": 4.5,
                "time": "2.5 Yrs"
            }
        ]
    }
}
export async function get_project_data(jwt) {
  try {
    const res = {statusCode:null, data: null};
    const response = await fetch(BASE_URL() + "/bb/projects", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ jwt 
      }
    }); 
    res.statusCode = response.status;
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: null}; 
  }
}
export async function get_tag_data(jwt) {
  try {
    const res = {statusCode:null, data: null};
    const response = await fetch(BASE_URL() + "/bb/tags", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ jwt 
      }
    }); 
    res.statusCode = response.status;
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: null}; 
  }
}
export async function get_contact_data(jwt) {
  try {
    const res = {statusCode:null, data: null};
    const response = await fetch(BASE_URL() + "/bb/getContact", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ jwt 
      }
    }); 
    res.statusCode = response.status;
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: null}; 
  }
}
export async function getTokenWeb(cookieSetterFunc, force = false){
  try {
    if (force == true || (getCookie("refresh_token") == null || getCookie("refresh_token").toString().length == 0)) {
      const token = await get_token()
      if (token != null){
        cookieSetterFunc("refresh_token", token.toString())
        return token;
      }
      else {
        console.error("[JWT] Fetching token fail")
        return null;
      }
    }
    else{
      console.error("[JWT] Token not fetched.")
      return getCookie("refresh_token");
    }

  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
export async function verify_captcha_backend(captchaVal, form_name, form_email, form_body){
  try {
    const _body = {
      g_recaptcha_response: captchaVal,
      form_name: form_name,
      form_email: form_email, 
      form_body: form_body
    }
    const res = {statusCode:null, data: null};
    const response = await fetch("/recaptcha-verify", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_body),
    }); 
    res.statusCode = response.status;
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: null}; 
  }
}
export async function verify_captcha_and_send_email(g_recaptcha_response, form_name, form_email, form_body) {
  try {
    let _body = {
      'g-recaptcha-response': g_recaptcha_response,
      'contact_name': form_name, 
      'contact_email': form_email, 
      'contact_msg': form_body
    };
    const res = {statusCode:null, data: null};
    const response = await fetch(BASE_URL() + "/bb/send_email/", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ getCookie("refresh_token") 
      },
      body: JSON.stringify(_body),
    }); 
    res.statusCode = response.status;
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: null}; 
  }
}