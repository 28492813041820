import React from "react";
import {useNavigate} from "react-router-dom";
import {homePageToAboutPage} from "../../common/js/animate.js"
import {navClickedGAEvent} from "../../common/js/ga4.js"
import { LazyLoadImage } from "react-lazy-load-image-component";

const WelcomeComponent = () => {
  const navigate = useNavigate();
  return (
    <>
     <div id="welcomeComponent" className="centerContent">
        <div className="spacer"></div>
        <div className="mainName flexCol">
                <div className="flexCol">
                  <span aria-label="Go To About Me" onClick={(e) => {navClickedGAEvent("name-main"); homePageToAboutPage(navigate)}} className='nameTitle'>ALEKSANDRA KUKIELKO</span>
                  <div aria-label="Go To About Me" onClick={(e) => {navClickedGAEvent("name-main"); homePageToAboutPage(navigate)}} className='nameSubtitle'>
                        <span>full stack developer</span>
                        <span>design enthusiast</span>
                    </div>
                </div>
                <LazyLoadImage src={"/assets/img/me-main.png"} alt={'Aleks'} />
            </div>
     </div>
    </>
  );
};

export default WelcomeComponent;
