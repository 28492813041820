import React, { useState, useEffect, useContext } from "react";
import { TabContext } from "../../App"
import {readMoreClickedGAEvent, linkClickedGAEvent} from "../../common/js/ga4.js"
import LoaderComponent from "../Common/LoaderComponent.js";

export default function AboutComponent() {  
  const [aboutCardData, setAboutCardData] = useState({});
  const {GetDataForPage} = useContext(TabContext)
  const [readingStepNum, setReadingStepNum] = useState(1);
  const [dataFetchStatus, setDataFetchStatus] = useState(-1);
  const paragraph_max_steps = 6;
  const UNICODE_BULLET = '\u2022';

  useEffect(() => { 
    fetchData(); 
  },[]);
  
  const incrementStepNum = () => {  
    let currStep = readingStepNum
    if (currStep < paragraph_max_steps){
      readMoreClickedGAEvent(currStep);
      setReadingStepNum(currStep + 1);
    }
    else {
      //back to top
      document.getElementById('about_box').scrollIntoView({ behavior: 'smooth'});
    }
  }
  const fetchData = async () => {
    let data = await GetDataForPage("About")
    if (data){
      setAboutCardData(data);
      setDataFetchStatus(1);
    }
    else{
      setDataFetchStatus(0);
    }
}
  return (
      <div id="about_container" className="flexCol">
        <div id="about_box">
        <div className="divTitle">
          <h2><strong>ABOUT ME</strong></h2>
          <h3>You can call me Aleks for short.</h3>
        </div> 
        <div id="about_header">
          <div className="about_paragraph top">
          {dataFetchStatus == -1 ? 
            <div className="loader_container">
                <LoaderComponent></LoaderComponent> 
            </div> 
            :
            <>
              <div className="about_card">
                <div className="card_container">
                    {aboutCardData["card_one"] == null ?(
                      <span>Oops! Data could not be retrieved.</span>
                    ):(             
                      aboutCardData["card_one"].map(item => 
                        <div className="flexCol aboutMeItem">
                          <div className='aboutMeQ'>
                            <label><strong>{item.key}</strong></label>
                          </div>
                          <div className='aboutMeA'>
                            <span className="about_item_value">{item.value}</span>
                          </div>
                        </div>)
                      )} 
                </div>
              </div>
            </>
            }
            <img alt="Self portrait by Aleks" src="/assets/img/about-me.png"></img>
          </div>
              <div id="about_paragraph_container">
              <div className="about_paragraph left">
                  <p><strong>I learned my first coding lessons through my middle school <a onClick={()=>{linkClickedGAEvent("neopets")}} target="_blank" rel="noopener noreferrer" href="https://www.neopets.com/help/html1.phtml">Neopets</a> account.
                  In high school, I was editing and customizing Myspace profiles for my friends. </strong>
                  </p>
              </div>
              <div style={{display: readingStepNum >= 2 ? "block" : "none"}} className="about_paragraph right">
                <p>
                  Then it was the absurdity and fun that was <strong>Visual Basic</strong> in my <strong>high school CS programming class</strong>. 
                  I was the only girl in a class of 10, but that didn't stop me from cracking jokes and making silly little programs that reflected my sense of humor.
                </p>
              </div>
              <div style={{display: readingStepNum >= 3 ? "block" : "none"}}  className="about_paragraph left">
                <p>
                  <strong>In college</strong>, I began struggling with fundamental concepts.  I was scraping by, but I felt like I was falling behind.
                  How come I was unable to replicate what I learned in lecture?  Why couldn't I get any of my code to compile, and what the hell are these error messages trying to tell me?  
                  My peers could come up with straight forward answers, and I often had to ask for help.
                  It took me a long time to find my love for programming - post undergrad to be exact.  I realized - A-HA! I am a visual learner!
                  My college courses were nothing but slides full of text - online documentation lacked parseability, structure, it was uninviting and overwhelming.
                  When I gave it a second chance - I realized that by playing to my strengths, I could catch up quickly and be hungry for more.
                </p> 
              </div>
              <div style={{display: readingStepNum >= 4 ? "block" : "none"}} className="about_paragraph right">
                <p>
                  <strong>At my first full time job, </strong> I experienced challenges and successes in the e-commerce world.
                  I was put on a myriad of projects I had zero experience with.  One by one, I got developments, databases, deployments and data structures under my belt. 
                  Programmers can sometimes be difficult to work with - when I communicated promptly and clearly, and was happy to listen, my clients and teammates went out of their way to express gratitude.
                  Eventually, I was leading new projects, calculating estimates, assigning tasks, doing production deployments.  
                  While I felt behind my peers at some point, this experience gave me the confidence that not everyone learns and succeeds in the same ways.
                </p>
              </div>
              <div style={{display: readingStepNum >= 5 ? "block" : "none"}}  className="about_paragraph left">
                <p>
                  <strong>Today,</strong> I'm a full-stack developer with a solid foundation in the .NET and Mobile stacks.
                  I picked up the React stack most recently - this site is the fruit of my labors.
                  I pour my heart and creativity into all aspects of my work. 
                </p> 
              </div>
              <div style={{display: readingStepNum >= 6 ? "block" : "none"}}  className="about_paragraph right">
                <p>
                  <strong>What's next up for me? I'm excited to find out!</strong>
                </p> 
              </div>
              <div className="about_paragraph more">                
                  <svg onClick={() => { incrementStepNum()}} fill="#000000" id="Layer_1" viewBox="0 0 512 512">
                    <g>
                      <g>
                        <path d="M467.701,62.386c-2.194-0.798-54.397-19.534-107.604-19.534c-46.356,0-91.919,14.218-104.329,18.408    c-12.411-4.19-57.957-18.408-104.314-18.408c-53.207,0-105.218,18.736-107.412,19.534c-3.747,1.362-6.117,4.924-6.117,8.911    v322.372c0,3.093,1.375,5.991,3.91,7.766c1.616,1.132,3.453,1.715,5.374,1.715c1.09,0,2.154-0.188,3.207-0.572    c0.505-0.183,51.138-18.391,101.038-18.391c49.785,0,100.544,18.208,101.051,18.394c0.079,0.028,0.156,0.044,0.235,0.072    c0.142,0.046,0.282,0.086,0.426,0.125c0.292,0.083,0.583,0.151,0.878,0.204c0.146,0.028,0.289,0.053,0.436,0.074    c0.334,0.044,0.669,0.067,1.005,0.076c0.084,0.002,0.167,0.018,0.25,0.018c0.018,0,0.037-0.005,0.054-0.005    c0.473-0.002,0.943-0.049,1.411-0.123c0.138-0.021,0.273-0.051,0.412-0.079c0.382-0.079,0.76-0.178,1.132-0.303    c0.076-0.026,0.155-0.035,0.23-0.063c0.506-0.183,51.155-18.391,101.056-18.391c49.785,0,100.552,18.208,101.059,18.394    c2.905,1.056,6.278,0.623,8.81-1.148c2.532-1.775,4.174-4.671,4.174-7.764V71.297C474.074,67.31,471.447,63.748,467.701,62.386z     M246.518,380.61c-18.963-5.743-56.644-15.387-94.815-15.387c-38.171,0-75.852,9.644-94.815,15.387V78.119    c14.222-4.819,55.184-16.303,94.815-16.303c39.545,0,80.593,11.49,94.815,16.308V380.61z M455.111,380.61    c-18.963-5.743-56.644-15.387-94.815-15.387c-38.171,0-75.852,9.644-94.815,15.387V78.119    c14.222-4.819,55.184-16.303,94.815-16.303c39.545,0,80.593,11.49,94.815,16.308V380.61z"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M502.518,90.26c-5.236,0-9.481,4.246-9.481,9.482v331.48H293.661c-5.236,0-9.216,4.618-9.216,9.854v9.109h-56.889v-8.977    c0-5.236-4.51-9.986-9.746-9.986H18.963V99.741c0-5.236-4.245-9.482-9.481-9.482S0,94.505,0,99.741v341.467    c0,5.236,3.98,8.977,9.216,8.977h199.376v9.854c0,5.236,3.98,9.109,9.216,9.109h75.852c5.236,0,9.747-3.873,9.747-9.109v-9.854    h199.072c5.236,0,9.521-3.873,9.521-9.109V99.741C512,94.505,507.755,90.26,502.518,90.26z"/>
                      </g>
                    </g>
                  </svg>
                  <span>
                    {readingStepNum < paragraph_max_steps ?
                      "MORE" : 
                      "B A C K "+ UNICODE_BULLET +" T O "+ UNICODE_BULLET + " T O P"}
                  </span>
              </div>
          </div>
        </div>
        </div>
      </div>
    )
}
